//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Overview extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Video, Target } = this.props
        //Element
        let Source = "https://erka.s3.eu-north-1.amazonaws.com/video/"
        //Element
        let Media  = Source + Video
        //Element
        const Content = React.createElement( Container, {},
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 b3d6s6" },
                        //Element
                        React.createElement( "h3", { className: "float-start w-auto p-0 m-0 lh-1 o37v2n" },
                            //Element
                            Title
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-start p-0 m-0 mt-5 iga296" },
                        //Element
                        React.createElement( "video", { className: "float-start w-100 h-100", playsInline: true, src: Media, controls: false, loop: true, autoPlay: true, muted: "muted" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 mt-5 b3d6s6" },
                        //Element
                        React.createElement( "p", { className: "float-start w-auto p-0 m-0 t7i77g" },
                            //Element
                            "LG Klima ile her mevsimde konforunuzu sağlayın. Yazın serin kalın kışın ise sıcak tutun. Özel tasarlanmış klima koleksiyonumuzla",
                            //Element
                            React.createElement( "br", {} ),
                            //Element
                            "her mevsim için ideal sıcaklığı yaşayın."
                        ),
                        //Element
                        React.createElement( "a", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-3 o10v60", href: Target },
                            //Element
                            React.createElement( "strong", {},
                                //Element
                                "LG’nin Klima Serisini Tarayın"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Overview.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Video   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Overview;