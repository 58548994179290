//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Menu from "./Footer/Menu";
//Install
import Connect from "./Footer/Connect";
//Install
import Copyright from "./Footer/Copyright";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "footer", { className: "float-start w-100 p-0 m-0 zh731r" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 p920w3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 uw27j9" },
                                //Element
                                React.createElement( Menu, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:1 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 w42bk5" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 b205p1" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 uw27j9" },
                                //Element
                                React.createElement( Connect, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 uw27j9" },
                                //Element
                                React.createElement( Copyright, { ID: "0", Title: "Telif Hakkı © 2024 Erka Klima Sistemleri Tüm Hakları Saklıdır" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Footer;