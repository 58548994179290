//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Features extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 pe-5 m-0 s4lCEP" },
            //Element
            React.createElement( "span", { className: "float-start w-auto p-0 m-0 Yy2TKZ" },
                //Element
                Title
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 rSW7Yc" },
                //Element
                Summary
            ),
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 OO3Kdm", href: Target },
                //Element
                "Hemen Bakın"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Features.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Features;