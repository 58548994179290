//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Brand from "./Header/Brand";
//Install
import Menu from "./Header/Menu";
//Install
import SET from "./Header/SET";
//Install
import Mobile from "./Mobile/Default";
//Install
import Submenu from "./Header/Submenu";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "header", { className: "float-start w-100 p-0 m-0 b397vj" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 jd169t" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( Brand, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:7 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( SET, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none jq9p54" },
                //Element
                React.createElement( Mobile, {} )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;