//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 t405qk" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "p", { className: "float-start w-100 p-0 pt-1 pb-1 m-0 mt-4 mb-3 u2p43p" },
                        //Element
                        Title
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "a", { className: "float-end w-auto p-0 pt-1 pb-1 m-0 mt-4 mb-3 u2p43p", href: "/" },
                        //Element
                        "English"
                    ),
                    //Element
                    React.createElement( "p", { className: "float-end w-auto p-0 pt-1 pb-1 m-0 me-3 mt-4 mb-3 u2p43p" },
                        //Element
                        "Türkçe"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Copyright.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Copyright;