//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Kariyer Fırsatları - Erkaklima.com.tr"
        //Element
        Description.content = "LG Electronics, hayallerinizi gerçekleştirmenize olanak tanır ve mesleki gelişim için harika fırsatlar sunar."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/kariyer"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Kariyer Fırsatları - Erkaklima.com.tr"
        //Element
        Description.content = "LG Electronics, hayallerinizi gerçekleştirmenize olanak tanır ve mesleki gelişim için harika fırsatlar sunar."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/kariyer"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Kariyer Fırsatları - Erkaklima.com.tr"
        //Element
        Description.content = "LG Electronics, hayallerinizi gerçekleştirmenize olanak tanır ve mesleki gelişim için harika fırsatlar sunar."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Kariyer Fırsatları - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "LG Electronics, hayallerinizi gerçekleştirmenize olanak tanır ve mesleki gelişim için harika fırsatlar sunar."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Kariyer Fırsatları", Sub: "Ekibimize Katıl", Target: "/kariyer" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "Kariyer Fırsatları"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "LG Electronic hayallerinizi gerçekleştirmenize olanak tanır ve mesleki gelişim için harika fırsatlar sunar."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "LG olarak, Doğru Kişileri belirlemek, işe almak ve bizimle çalışmaya devam etmelerini sağlamak suretiyle yüksek standartlarımızı sürdürüyoruz. Doğru İnsanlar kimlerdir? Yaratıcılıkla ve en üst düzeyde performans gösterme arzusuyla hayallerinin peşinden koşan insanlar. Kararlı ve istekli takım oyuncuları."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Önce daima müşteriyi düşünen yenilik takipçileri. Hem profesyonellik hem de küresel sahnede aktif rol alma arzusuna sahip meraklı düşünürler. LG'nin çizdiği yola değer veren dürüst insanlar."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Başarı için gerekli olan farklı bakış açılarına değer vererek, insanları yalnızca yeteneklerinin, tutkularının, fikirlerinin ve sonuçlarının kalitesine göre işe almayı ve iş birliğini sürdürmeyi amaçlıyoruz. Bunun gerçekleşmesi için, farklı değer ve inançlara sahip bireylerin ekip olarak birlikte çalışabileceği bir ortamı mümkün kılan, ayrımcılık karşıtı bir çalışma politikası oluşturduk."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Çalışanlarımız hayatın her kesiminden gelir ve farklı geçmişleri de şirketimizin uluslararası karakterini yansıtmaktadır. LG Electronics, dünya çapındaki tüm ofislerinde aynı işe alma ilkelerini uygular."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 s4096n" },
                                    //Element
                                    "Adaylar, ilk olarak işe alım ilanlarımızdan birine başvurmalıdır. Başvurunuzun İnsan Kaynakları Departmanımıza ulaşmasını sağlamak için talimatları izleyin. Tüm başvuru materyallerini aldığımız andan itibaren gözden geçiririz, deneyiminizin ve becerilerinizin pozisyon için uygun olup olmadığını belirleriz."
                                ),
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 s4to31", href: "mailto:info@erkaklima.com.tr" },
                                    //Element
                                    "Hemen Başvur"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;