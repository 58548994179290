//Install
import React, { Component } from "react";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../../Content/Headline";
//Install
import Breadcrumb from "../../../Shortcode/Breadcrumb/Overview";
//Install
import Photo from "../../../Shortcode/Product/Photo";
//Install
import Location from "../../../Content/Location";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH - Erkaklima.com.tr"
        //Element
        Description.content = "Keşfedin : UV ARTCOOL Inverter Wi-Fi Akıllı Hijyen Klima 24000 Btu Enerji A++ Duvar Tipi Aynalı SİYAH Erkaklima.com.tr'de"
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH - Erkaklima.com.tr"
        //Element
        Description.content = "Keşfedin : UV ARTCOOL Inverter Wi-Fi Akıllı Hijyen Klima 24000 Btu Enerji A++ Duvar Tipi Aynalı SİYAH Erkaklima.com.tr'de"
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH - Erkaklima.com.tr"
        //Element
        Description.content = "Keşfedin : UV ARTCOOL Inverter Wi-Fi Akıllı Hijyen Klima 24000 Btu Enerji A++ Duvar Tipi Aynalı SİYAH Erkaklima.com.tr'de"
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Keşfedin : UV ARTCOOL Inverter Wi-Fi Akıllı Hijyen Klima 24000 Btu Enerji A++ Duvar Tipi Aynalı SİYAH Erkaklima.com.tr'de"
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Klima", Sub: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 j526i9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d6203g" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Photo, { ID: "0", Title: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima", Image: "013.jpg" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Photo, { ID: "0", Title: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima", Image: "014.jpg" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Photo, { ID: "0", Title: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima", Image: "015.jpg" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Photo, { ID: "0", Title: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima", Image: "016.jpg" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-4 m-0 n825zt" },
                                //Element
                                React.createElement( "label", { className: "float-start w-100 p-0 m-0 q97n5j" },
                                    //Element
                                    "Ürün Kodu : AC24BH"
                                ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mt-3 t5t52g" },
                                    //Element
                                    "UV ARTCOOL Inverter Wi-Fi Akıllı Hijyen Klima 24000 BTU Enerji A++ Duvar Tipi Aynalı SİYAH"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 p96532" },
                                    //Element
                                    "LG UV Artcool 24.000 BTU/h Duvar Tipi Split Klima, evinizi sağlıklı ve konforlu bir şekilde iklimlendirmek üzere geliştirilmiş üst düzey özelliklere",
                                    //Element
                                    React.createElement( "br", {} ),
                                    //Element
                                    "sahip bir modeldir."
                                ),
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 ps-5 pe-5 m-0 mt-4 k38o9g", href: "/hemen-ulasin" },
                                    //Element
                                    "Hemen Satın Al"
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e1f54e" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t4q95f" },
                                //Element
                                React.createElement( "ul", { className: "float-start w-100 p-0 m-0 w298l4" },
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Marka"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "LG Electronics"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Model"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "UV Artcool"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Ürün Tipi"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Klima"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Renk"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Munsell 9.54Y 8.34/1.31 RAL 9001"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Kapasite"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "24.000 BTU"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Plasmaster Ionizer"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Evet"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "ThinQ"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Evet"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "UVNano"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Evet"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Enerji Sınıfı"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "A++"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Enerji Tüketimi"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "250 kWh"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Soğutucu"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "R32"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "DUAL Inverter Compressor"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Evet"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Üretici Firma"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "LG"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Üretim"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "Türkiye"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Garanti Süresi"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "24 AY"
                                                    )
                                                )
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( "li", { className: "float-start w-100 p-0 ps-4 pe-4 m-0" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 w3m19z" },
                                                        //Element
                                                        "Stok Kodu"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:6 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 k02v89" },
                                                    //Element
                                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 a688om" },
                                                        //Element
                                                        "AC24BH"
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ChjC3E" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 hX85rr" },
                                //Element
                                React.createElement( Location, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;