//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";
//Install
import Reference from "../../Shortcode/Reference/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Referanslar : Kimlere hizmet sunuldu? - Erkaklima.com.tr"
        //Element
        Description.content = "Eğer hizmet verdiğimiz kesimleri merak ediyorsanız ve müşteri profillerimizi görmek istiyorsanız, sitemizi ziyaret etmenizi öneririz!"
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/referanslar"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Referanslar : Kimlere hizmet sunuldu? - Erkaklima.com.tr"
        //Element
        Description.content = "Eğer hizmet verdiğimiz kesimleri merak ediyorsanız ve müşteri profillerimizi görmek istiyorsanız, sitemizi ziyaret etmenizi öneririz!"
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/referanslar"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Referanslar : Kimlere hizmet sunuldu? - Erkaklima.com.tr"
        //Element
        Description.content = "Eğer hizmet verdiğimiz kesimleri merak ediyorsanız ve müşteri profillerimizi görmek istiyorsanız, sitemizi ziyaret etmenizi öneririz!"
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Referanslar : Kimlere hizmet sunuldu? - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Eğer hizmet verdiğimiz kesimleri merak ediyorsanız ve müşteri profillerimizi görmek istiyorsanız, sitemizi ziyaret etmenizi öneririz!"
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Referanslar", Sub: "Kimlere Hizmet Sunuldu?", Target: "/referanslar" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "Kurumsal Referanslar"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "LG Electronics, Müşterilerin gereksinimlerine özel olarak tasarlanmış ve kullanıcıların en üst düzeyde verim elde edebileceği ürün ve çözümleri sunar."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "LG Electronics, müşterilerinin benzersiz ihtiyaçlarını karşılamak için özel olarak tasarlanmış ürün ve çözümleriyle öne çıkar."
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 u646ho" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Hasan Usta Kebap", Photo: "001.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "TAT Kimya", Photo: "002.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Mondi", Photo: "003.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Ciğerci Hadi", Photo: "004.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Ekrem Tır", Photo: "005.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "DeFacto", Photo: "006.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Özel Yenişehir Hastanesi", Photo: "007.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Ender Mağazaları", Photo: "008.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Doğa Koleji", Photo: "009.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Radisson Blu Hotel", Photo: "010.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Novotel", Photo: "011.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Sanko Holding", Photo: "012.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Altınözü Belediyesi", Photo: "013.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Erten Otel", Photo: "014.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Çetinkaya", Photo: "015.jpg", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Çukurova Üniversitesi", Photo: "016.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Müze Müdürlüğü", Photo: "017.png", Target: "/" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:2 },
                                            //Element
                                            React.createElement( Reference, { ID: "0", Title: "Yapı Kredi", Photo: "018.jpg", Target: "/" } )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;