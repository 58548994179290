//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Heading extends Component {
    //Element
    GET = () => {
        //Element
        const { Target } = this.props
        //Element
        window.location.href = Target
    }
    //Element
    render() {
        //Element
        const { ID, Title, Summary } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 n5e3z9" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 k28362" },
                //Element
                Title
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 ps-1 m-0 mt-3 a4396d" },
                //Element
                Summary
            ),
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ms-1 mt-4 z274br", type: "button", onClick: this.GET },
                //Element
                "Hemen Bakın"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Heading.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Heading;