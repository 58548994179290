//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";

//Element
class Location extends Component {
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        let Panel   = document.getElementsByClassName( "Ch3G6o" )[0]
        //Element
        let Message = document.getElementsByClassName( "WGjL7M" )[0]
        //Element
        emailjs.sendForm( process.env.REACT_APP_Service, process.env.REACT_APP_Template, "#Web_P7391O", process.env.REACT_APP_Public )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                Panel.classList.toggle( "d-none" )
                //Element
                Message.innerHTML = "Tebrikler Başarı ile Gönderildi! En Kısa Sürede Dönüş Yapılacaktır."
            }
        }, (error) => {
            //Element
            Panel.classList.toggle( "d-none" )
            //Element
            Message.innerHTML = error.text
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:7 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 y5WIzQ" },
                    //Element
                    React.createElement( "iframe", { width: "600", height: "450", src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.5364277812887!2d35.27469377565701!3d37.04469807217838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1528891bc68489ad%3A0x9d4c5848bf97aaa!2sErka%20Klima!5e0!3m2!1str!2str!4v1712591145672!5m2!1str!2str", className: "float-start w-100 bg-transparent border-0" } )
                )
            ),
            //Element
            React.createElement( Col, { md:5 },
                //Element
                React.createElement( "form", { id: "Web_P7391O", name: "Web_P7391O", className: "float-start w-100 p-5 m-0 zX2EQz", method: "GET", onSubmit: this.Send },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ecx0eZ" },
                                //Element
                                React.createElement( "input", { id: "Web_Q40U3M", name: "Web_Q40U3M", className: "float-start w-100 p-0 ps-4 pe-4 m-0 oSb1Ts", placeholder: "Ad", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ecx0eZ" },
                                //Element
                                React.createElement( "input", { id: "Web_C731WV", name: "Web_C731WV", className: "float-start w-100 p-0 ps-4 pe-4 m-0 oSb1Ts", placeholder: "Soyad", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ecx0eZ" },
                                //Element
                            React.createElement( "input", { id: "Web_D165D2", name: "Web_D165D2", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 oSb1Ts", placeholder: "Telefon Numarası", type: "tel", minlenght: "11", maxlenght: "11", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ecx0eZ" },
                                //Element
                                React.createElement( "input", { id: "Web_W9L09C", name: "Web_W9L09C", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 oSb1Ts", placeholder: "E-posta", type: "email", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ecx0eZ" },
                                //Element
                                React.createElement( "textarea", { id: "Web_F1U005", name: "Web_F1U005", className: "float-start w-100 p-0 ps-4 pe-4 pt-3 pb-3 m-0 mt-4 tI4TZu", placeholder: "Mesaj", rows: "5", cols: "30", maxlenght: "3800", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Ecx0eZ" },
                                //Element
                                React.createElement( "button", { id: "Web_D4D96T", name: "Web_D4D96T", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 e5M8We", type: "submit" },
                                    //Element
                                    "Hemen Gönder"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 d-none Ch3G6o" },
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 WGjL7M" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Location;