//Install
import React, { Component } from "react";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 z8310x" },
            //Element
            React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 y321bf" },
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "LG Klima", href: "/" },
                        //Element
                        "LG Klima"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "Kurumsal", href: "/kurumsal" },
                        //Element
                        "Kurumsal"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "Ürünler", href: "/urunler" },
                        //Element
                        "Ürünler"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "Servis Randevusu", href: "/servis-randevusu" },
                        //Element
                        "Servis Randevusu"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "Referanslar", href: "/referanslar" },
                        //Element
                        "Referanslar"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "Kariyer", href: "/kariyer" },
                        //Element
                        "Kariyer"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto position-relative", title: "Hemen Ulasin", href: "/hemen-ulasin" },
                        //Element
                        "Hemen Ulasin"
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;