//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "./Content/Headline";
//Install
import Features from "./Content/Features";
//Install
import Overview from "./Content/Overview";
//Install
import Video from "./Content/Video";
//Install
import News from "./Content/News";
//Install
import Product from "./Content/Product";
//Install
import Services from "./Content/Services";
//Install
import Location from "./Content/Location";
//Install
import Reference from "./Content/Reference";

//Element
class Main extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Erka İklimlendirme Sistemleri : LG Klima ve Hava Temizleyici Ürünleri - Erkaklima.com.tr"
        //Element
        Description.content = "Adana LG Bayi, Erka İklimlendirme Sistemleri : Bireysel ve LG Klima ve Hava Temizleme Cihazları İçin Tedarik, Bakım ve Onarım Hizmetleri Sunulmaktadır."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Erka İklimlendirme Sistemleri : LG Klima ve Hava Temizleyici Ürünleri - Erkaklima.com.tr"
        //Element
        Description.content = "Adana LG Bayi, Erka İklimlendirme Sistemleri : Bireysel ve LG Klima ve Hava Temizleme Cihazları İçin Tedarik, Bakım ve Onarım Hizmetleri Sunulmaktadır."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Erka İklimlendirme Sistemleri : LG Klima ve Hava Temizleyici Ürünleri - Erkaklima.com.tr"
        //Element
        Description.content = "Adana LG Bayi, Erka İklimlendirme Sistemleri : Bireysel ve LG Klima ve Hava Temizleme Cihazları İçin Tedarik, Bakım ve Onarım Hizmetleri Sunulmaktadır."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Erka İklimlendirme Sistemleri : LG Klima ve Hava Temizleyici Ürünleri - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Adana LG Bayi, Erka İklimlendirme Sistemleri : Bireysel ve LG Klima ve Hava Temizleme Cihazları İçin Tedarik, Bakım ve Onarım Hizmetleri Sunulmaktadır."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 A8vD2r" },
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 jWw0y4" },
                    //Element
                    React.createElement( Features, { ID: "0", Title: "Hızlı ve Kolay Kurulum", Summary: "Kurulumu daha az zaman ve çaba gerektiren klimalar ile serin havanın keyfini hemen yaşamaya başlayın.", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 GYBm67" },
                    //Element
                    React.createElement( Features, { ID: "0", Title: "Enerji Faturalarından Tasarruf Edin", Summary: "Elektrik faturanızı azaltmak için daha verimli bir soğutma sistemine geçerek enerji tüketiminizi düşürebilirsiniz.", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 jWw0y4" },
                    //Element
                    React.createElement( Features, { ID: "0", Title: "Akıllı Kontrol, Daha Akıllı Yaşam", Summary: "LG ThinQ™ uygulaması, klimanıza benzersiz bir erişim sağlayarak daha önce deneyimlemediğiniz bir bağlantı kolaylığı sunar.", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-25 p-0 m-0 GYBm67" },
                    //Element
                    React.createElement( Features, { ID: "0", Title: "10 Yıl Garanti", Summary: "Bir kompresörün 10 yıl garantiyle sunulması, en üst düzey performansını daha uzun süre koruyacağını gösterir.", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 w4552r" },
                //Element
                React.createElement( Overview, { ID: "0", Title: "LG Klima Hakkında Daha Fazla Bilgi Edinin", Summary: "LG Klima ile her mevsimde konforunuzu sağlayın. Yazın serin kalın kışın ise sıcak tutun. Özel tasarlanmış klima koleksiyonumuzla her mevsim için ideal sıcaklığı yaşayın.", Video: "002.mp4", Target: "/kurumsal" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 i5834n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 uF4STK" },
                                //Element
                                React.createElement( Video, { ID: "1" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( News, { ID: "0", Title: "Daha Sıcak, Daha Serin, DUAL ile Daha İyi.", Summary: "LG ARTCOOL ile enerji faturalardan tasarruf edin, Dört mevsim yıl boyunca verimli ısıtma ve soğutmanın keyfini çıkarın.", Photo: "", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 i5834n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( Product, { ID: "AC24BH", Title: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH", Photo: "013.jpg", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( Product, { ID: "PC18SQ", Title: "LG DualCool A++ 18000 BTU Duvar Tipi Inverter Klima PC18SQ", Photo: "003.jpg", Target: "/urun/lg-dualcool-a-18000-btu-duvar-tipi-inverter-klima-pc18sq" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( Product, { ID: "S3-W12JA3AA", Title: "LG Dual Eco A++ 12000 BTU Duvar Tipi Inverter Klima S3-W12JA3AA", Photo: "005.jpg", Target: "/urun/lg-dual-eco-a-12000-btu-duvar-tipi-inverter-klima-s3-w12ja3aa" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( Product, { ID: "DC09RTH", Title: "LG UV Sirius A++ 9000 BTU Duvar Tipi Inverter Klima DC09RTH", Photo: "009.jpg", Target: "/urun/lg-uv-sirius-a-9000-btu-duvar-tipi-inverter-klima-dc09rth" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 i5834n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( News, { ID: "0", Title: "Ailenizi Mikroplardan Koruyun.", Summary: "Babalar yemek pişirdiğinde, mutfakta dumanlar yükselir. Fakat PuriCare her zaman havayı temizlemek için yanınızda olur.", Photo: "", Target: "/" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 uF4STK" },
                                //Element
                                React.createElement( Video, { ID: "2" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( Product, { ID: "AS10GDWH0", Title: "PuriCare 360 Hava Temizleyici Çoklu Filtre Sistemi AS10GDWH0", Photo: "017.jpg", Target: "/urun/lg-puricare-360-hava-temizleyici-oklu-filtre-sistemi-as10gdwh0" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 x2g758" },
                                //Element
                                React.createElement( Product, { ID: "AS65GDWH0", Title: "PuriCare 360 Hava Temizleyici Çoklu Filtre Sistemi AS65GDWH0", Photo: "021.jpg", Target: "/urun/lg-puricare-360-hava-temizleyici-oklu-filtre-sistemi-as65gdwh0" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 ChjC3E" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 hX85rr" },
                                //Element
                                React.createElement( Location, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 mt-5 hm2544" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Services, { ID: "0", Title: "Montaj Talebi", Summary: "Kaliteli klima montajıyla uzun süreli konforunuzu garanti altına alın ve güvenle kullanın.", Target: "/servis-randevusu" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Services, { ID: "0", Title: "Bakım Talebi", Summary: "Klimanızın performansını artırmak ve uzun ömürlü kullanım sağlamak için bakım zamanı geldi.", Target: "/servis-randevusu" } )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( Services, { ID: "0", Title: "Sorun Giderme", Summary: "Cihazınızın optimum performansını sürdürebilmesi için bakım ve onarım gerekebilir.", Target: "/servis-randevusu" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 p91zc1" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Sanko Holding", Photo: "008.jpg", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Ender Giyim", Photo: "007.png", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Yapı Kredi", Photo: "006.jpg", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Doğa Koleji", Photo: "005.jpg", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Tat Kimya", Photo: "004.jpg", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "DeFacto", Photo: "003.jpg", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Ciğerci Hadi", Photo: "002.jpg", Target: "/referanslar" } )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Reference, { ID: "0", Title: "Ekrem Tır", Photo: "001.jpg", Target: "/referanslar" } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;