//Install
import React, { Component } from "react";
//Install
import { ReactComponent as X09P0B } from "../../../Media/Social/001.svg";
//Install
import { ReactComponent as N859X9 } from "../../../Media/Social/002.svg";
//Install
import { ReactComponent as IC94O6 } from "../../../Media/Social/003.svg";

//Element
class Default extends Component {
    //Element
    Facebook = (e) => {
        //Element
        e.preventDefault()
        //Element
        let GET      = "https://www.facebook.com/sharer/sharer.php?u="
        //Element
        let Location = window.location.href
        //Element
        window.location.href = GET + Location
    }
    //Element
    X = (e) => {
        //Element
        e.preventDefault()
        //Element
        let GET      = "https://twitter.com/intent/tweet?url="
        //Element
        let Location = window.location.href
        //Element
        window.location.href = GET + Location
    }
    //Element
    Linkedin = (e) => {
        //Element
        e.preventDefault()
        //Element
        let GET      = "https://www.linkedin.com/sharing/share-offsite/?url="
        //Element
        let Location = window.location.href
        //Element
        window.location.href = GET + Location
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-auto p-0 m-0 mt-5 q9s876" },
            //Element
            React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 e05z8a" },
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto", title: "Facebook", href: "/", onClick: this.Facebook },
                        //Element
                        React.createElement( X09P0B, {} )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto", title: "X", href: "/", onClick: this.X },
                        //Element
                        React.createElement( N859X9, {} )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto", title: "Linkedin", href: "/", onClick: this.Linkedin },
                        //Element
                        React.createElement( IC94O6, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;