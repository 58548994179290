//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m7373k" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate k05ss5" },
                        //Element
                        "Kurumsal"
                    ),
                    //Element
                    React.createElement( "menu", { className: "float-start w-100 p-0 m-0 mt-3 d9o0s6" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/kurumsal" },
                                //Element
                                "Genel Bilgilendirme"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/kariyer" },
                                //Element
                                "Kariyer"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/referanslar" },
                                //Element
                                "Kimlere Hizmet Sunuldu?"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/hemen-ulasin" },
                                //Element
                                "Hemen Ulasin"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m7373k" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate k05ss5" },
                        //Element
                        "Isıtma ve Soğutma"
                    ),
                    //Element
                    React.createElement( "menu", { className: "float-start w-100 p-0 m-0 mt-3 d9o0s6" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/urunler" },
                                //Element
                                "Klima"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/urunler" },
                                //Element
                                "Hava Bakımı"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m7373k" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate k05ss5" },
                        //Element
                        "Servis Randevusu"
                    ),
                    //Element
                    React.createElement( "menu", { className: "float-start w-100 p-0 m-0 mt-3 d9o0s6" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/servis-randevusu" },
                                //Element
                                "Montaj"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/servis-randevusu" },
                                //Element
                                "Bakım"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/servis-randevusu" },
                                //Element
                                "Sorun Giderme"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m7373k" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 text-truncate k05ss5" },
                        //Element
                        "Hemen Takip Edin"
                    ),
                    //Element
                    React.createElement( "menu", { className: "float-start w-100 p-0 m-0 mt-3 d9o0s6" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/" },
                                //Element
                                "Instagram"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/" },
                                //Element
                                "X"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/" },
                                //Element
                                "Youtube"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 m7373k" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-truncate k05ss5" },
                        //Element
                        "Müşteri Hizmetleri"
                    ),
                    //Element
                    React.createElement( "menu", { className: "float-start w-100 p-0 m-0 mt-3 d9o0s6" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "https://api.whatsapp.com/send/?phone=905321206083&text=Merhaba&type=phone_number&app_absent=0" },
                                //Element
                                "WhatsApp Destek"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "mailto:info@erkaklima.com.tr" },
                                //Element
                                "E-posta"
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Col, { md:3 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 m7373k" },
                    //Element
                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-truncate k05ss5" },
                        //Element
                        "Kişisel Verilerin Korunması"
                    ),
                    //Element
                    React.createElement( "menu", { className: "float-start w-100 p-0 m-0 mt-3 d9o0s6" },
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/" },
                                //Element
                                "Bilgi Güvenliği"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/" },
                                //Element
                                "Politikalar"
                            )
                        ),
                        //Element
                        React.createElement( "li", { className: "float-start w-100" },
                            //Element
                            React.createElement( "a", { className: "float-start w-100", href: "/" },
                                //Element
                                "Başvuru Formu"
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Menu;