//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Reference extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://erka.s3.eu-north-1.amazonaws.com/reference/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-3 pt-0 pb-0 m-0 mt-5 y6z998", href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-1 m-0 g659s4" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "141", height: "141" } )
            ),
            //Element
            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 text-center lh-1 o7z358" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Reference.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Reference;