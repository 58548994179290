//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Video extends Component {
    //Element
    render() {
        //Element
        const { ID } = this.props
        //Element
        let Media
        //Element
        if( ID == "1" ){
            //Element
            Media = React.createElement( "video", { className: "float-start w-100 h-100", playsInline: true, src: "https://erka.s3.eu-north-1.amazonaws.com/video/003.mp4", controls: true, loop: true, autoPlay: true, muted: "muted" } )
        //Element
        } else if( ID == "2" ){
            //Element
            Media = React.createElement( "video", { className: "float-start w-100 h-100", playsInline: true, src: "https://erka.s3.eu-north-1.amazonaws.com/video/004.mp4", controls: true, loop: true, autoPlay: true, muted: "muted" } )
        //Element
        } else {
            //Element
            Media = React.createElement( "video", { className: "float-start w-100 h-100", playsInline: true, src: "https://erka.s3.eu-north-1.amazonaws.com/video/003.mp4", controls: true, loop: true, autoPlay: false, muted: "muted" } )
        }
        //Element
        const Content = Media
        //Element
        return (
            Content
        );
    }
}
//Element
Video.propTypes = {
    ID : propTypes.string.isRequired
}
//Element
export default Video;