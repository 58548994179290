//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Product extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.erkaklima.com.tr/product/"
        //Element
        let Media  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-4 m-0 TQQa4G", title: Title, href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 s66l57" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 uF4STK" },
                            //Element
                            React.createElement( "img", { alt: Title, title: Title, src: Media, width: "416", height: "276" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 ps-3 pe-3 m-0 tVI0Qi" },
                            //Element
                            React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-uppercase W6E3mt" },
                                //Element
                                ID
                            ),
                            //Element
                            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 mt-2 text-center Ha7OWF" },
                                //Element
                                Title
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Product.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Product;