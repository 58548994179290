//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";
//Install
import Modal from "../../Shortcode/Services/Modal";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Ürün Desteği : Müşteri Memnuniyeti ve Sorun Çözümü - Erkaklima.com.tr"
        //Element
        Description.content = "Ürün desteği, müşterilerimizin satın aldıkları ürünlerle ilgili her türlü sorunlarını çözmek ve memnuniyetlerini sağlamak amacıyla oluşturduğumuz önemli bir hizmettir."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/servis-randevusu"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Ürün Desteği : Müşteri Memnuniyeti ve Sorun Çözümü - Erkaklima.com.tr"
        //Element
        Description.content = "Ürün desteği, müşterilerimizin satın aldıkları ürünlerle ilgili her türlü sorunlarını çözmek ve memnuniyetlerini sağlamak amacıyla oluşturduğumuz önemli bir hizmettir."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/servis-randevusu"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Ürün Desteği : Müşteri Memnuniyeti ve Sorun Çözümü - Erkaklima.com.tr"
        //Element
        Description.content = "Ürün desteği, müşterilerimizin satın aldıkları ürünlerle ilgili her türlü sorunlarını çözmek ve memnuniyetlerini sağlamak amacıyla oluşturduğumuz önemli bir hizmettir."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Ürün Desteği : Müşteri Memnuniyeti ve Sorun Çözümü - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Ürün desteği, müşterilerimizin satın aldıkları ürünlerle ilgili her türlü sorunlarını çözmek ve memnuniyetlerini sağlamak amacıyla oluşturduğumuz önemli bir hizmettir."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Ürün Desteği", Sub: "Servis Randevusu", Target: "/servis-randevusu" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "Servis Randevusu"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "Buradaki ürün destek ekibimiz, müşteri memnuniyetini her zaman ön planda tutarak sorunlarınızı çözmek için hazır bekliyor."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Ürün desteği, müşterilerimizin satın aldıkları ürünlerle ilgili her türlü sorunlarını çözmek ve memnuniyetlerini sağlamak amacıyla oluşturduğumuz önemli bir hizmettir. Biz, müşterilerimize ürünlerimizle ilgili en iyi deneyimi yaşatmayı taahhüt ediyoruz."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Ürün desteği departmanımız, müşterilerimizin karşılaşabileceği her türlü sorunu çözmek ve ürünlerimizi en verimli şekilde kullanabilmelerini sağlamak için buradadır. Herhangi bir alışveriş deneyiminde veya sonrasında ortaya çıkan her türlü sorun, müşterilerimize daha üst düzeyde hizmet sunmak için yeni fırsatlar yaratır ve bizi güçlendirir."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 mb-4 s4096n" },
                                    //Element
                                    "Müşterilerimizin geri bildirimleri, ürünlerimizi ve hizmetlerimizi sürekli olarak iyileştirmemize yardımcı olur. Bu nedenle, her türlü geri bildirimi değerli buluyor ve dikkatle inceliyoruz. Ürün desteği deneyiminizi iyileştirmek için sürekli olarak çalışıyoruz."
                                ),
                                //Element
                                React.createElement( Modal, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;