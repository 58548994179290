//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class News extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 h8j670" },
            //Element
            React.createElement( "h4", { className: "float-start w-auto p-0 m-0 lh-1 kd539k" },
                //Element
                Title
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 text-center ay75v7" },
                //Element
                Summary
            ),
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 text-center lh-1 h64061", href: Target },
                //Element
                "#LifeisGood"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
News.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default News;