//Install
import React, { Component } from "react";
//Install
import { ReactComponent as C367FI } from "../../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Overview extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Sub, Target } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 x0i603" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0", href: "/" },
                    //Element
                    "LG Klima"
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 ps-1 m-0 ms-2" },
                    //Element
                    React.createElement( C367FI, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 ps-1 m-0 ms-2", href: Target },
                    //Element
                    Title
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 ps-1 m-0 ms-2" },
                    //Element
                    React.createElement( C367FI, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 ps-1 m-0 ms-2" },
                    //Element
                    Sub
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Overview.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Sub    : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Overview;