//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as F511SJ } from "../../Media/Icon/008.svg";
//Install
import propTypes from "prop-types";

//Element
class Services extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 pe-5 ps-5 pt-5 pb-5 m-0 mt-4 mb-4 b87i0d", href: Target },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 m-0 h0y628" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 o8437e" },
                            //Element
                            Title
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 mb-3 z96m9l" },
                            //Element
                            Summary
                        ),
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 q656t5" },
                            //Element
                            React.createElement( F511SJ, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Services.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Services;