//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as X12FG9 } from "../../Media/Icon/015.svg";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "nav", { className: "float-start w-100 p-0 m-0 position-fixed d-none i64iv0" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 i1n3m3" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Başlangıç", href: "/" },
                                    //Element
                                    "Başlangıç",
                                    //Element
                                    React.createElement( X12FG9, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Kurumsal", href: "/kurumsal" },
                                    //Element
                                    "Kurumsal",
                                    //Element
                                    React.createElement( X12FG9, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Ürünler", href: "/urunler" },
                                    //Element
                                    "Ürünler",
                                    //Element
                                    React.createElement( X12FG9, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Servis Randevusu", href: "/servis-randevusu" },
                                    //Element
                                    "Servis Randevusu",
                                    //Element
                                    React.createElement( X12FG9, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Referanslar", href: "/referanslar" },
                                    //Element
                                    "Referanslar",
                                    //Element
                                    React.createElement( X12FG9, {} )
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", title: "Hemen Ulasin", href: "/hemen-ulasin" },
                                    //Element
                                    "Hemen Ulasin",
                                    //Element
                                    React.createElement( X12FG9, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Submenu;