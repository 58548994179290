//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Connect extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( Row, {},
            //Element
            React.createElement( Col, { md:12 },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 m7373k" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-3 h45j3c" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 k05ss5" },
                            //Element
                            "Kurumsal Bilgilendirme"
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 b61409" },
                            //Element
                            "Eğer herhangi bir konuda yardıma ihtiyacınız varsa, lütfen bize bildirin. Size yardımcı olmaktan memnuniyet duyarız."
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 m0947t" },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 d911nu" },
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 lh-1 c83375", href: "tel:+903225044455" },
                                    //Element
                                    "0322 504 44 55"
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-3 h45j3c" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 k05ss5" },
                            //Element
                            "Hemen Bilgi Edinin"
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 b61409" },
                            //Element
                            "Size yardımcı olabileceğim bir konu var mı? İletişim kurmak isterseniz, bizimle hemen irtibata geçebilirsiniz."
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 m0947t" },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 d911nu" },
                                //Element
                                React.createElement( "a", { className: "float-start w-auto p-0 m-0 lh-1 c83375", href: "https://api.whatsapp.com/send/?phone=905321206083&amp;text=Merhaba&amp;type=phone_number&amp;app_absent=0" },
                                    //Element
                                    "0532 120 60 83"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Connect;