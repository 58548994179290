//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.erkaklima.com.tr/product/"
        //Element
        let Media  = Source + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-4 m-0 mt-4 u2r3l1", title: Title, href: Target },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 v0116i" },
                        //Element
                        React.createElement( "img", { alt: Title, title: Title, src: Media, width: "199", height: "132" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 pe-2 m-0 s5p5x1" },
                        //Element
                        React.createElement( "h3", { className: "float-start w-auto p-0 m-0 m036r3" },
                            //Element
                            Title
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;