//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Figure from "../Shortcode/Content/Figure";
//Install
import Heading from "../Shortcode/Content/Heading";
//Install
import propTypes from "prop-types";

//Element
class Headline extends Component {
    //Element
    render() {
        //Element
        const Slider = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            pagination      : {
                //Element
                el          : ".y02vy3",
                //Element
                clickable   : true
            },
            //Element
            navigation      : {
                //Element
                nextEl      : ".w181kh",
                //Element
                prevEl      : ".t003r7"
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const { ID, Title, Summary, Video, Target } = this.props
        //Element
        let Source = "https://erka.s3.eu-north-1.amazonaws.com/video/"
        //Element
        let Media  = Source + Video
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mau703" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t748jl" },
                //Element
                React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: true, src: Media, controls: false, loop: true, autoPlay: true, muted: "muted", itemProp: "video" } )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 pt-5 m-0 position-absolute w9u19m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 w44r88" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Slider },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Heading, { ID: "0", Title: "LG DualCool Klimalarla Hızlı Soğutma, Hızlı Konfor", Summary: "LG DUAL Inverter Compressor ile zorlu iklimlerde bile rahat edin.", Target: Target } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Heading, { ID: "0", Title: "Daha Güçlü, Tasarruflu Güvenilir Klima", Summary: "LG DUAL Inverter Compressor™ ile daha hızlı ve konforlu soğutma.", Target: Target } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Heading, { ID: "0", Title: "Daha Verimli, Daha Hızlı DUAL Inverter Teknoloji.", Summary: "Daha Verimli, Daha Hızlı, DUAL Inverter Teknoloji", Target: Target } )
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute end-0 top-0 v258gv" },
                                    //Element
                                    React.createElement( Container, {},
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:12 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative z88b3c" },
                                                    //Element
                                                    React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 pt-5 m-0 position-absolute end-0 top-0 c7ar92" },
                                                        //Element
                                                        React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center rounded-circle position-relative t003r7", type: "button" } ),
                                                        //Element
                                                        React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-end p-0 m-0 y02vy3" } ),
                                                        //Element
                                                        React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center rounded-circle position-relative w181kh", type: "button" } ),
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Figure, {} )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Headline.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Video   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Headline;