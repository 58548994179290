//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as JY9F35 } from "../../Media/Brand/000.svg";
//Install
import { ReactComponent as Y1O941 } from "../../Media/Icon/012.svg";
//Install
import { ReactComponent as FV0J33 } from "../../Media/Icon/013.svg";
//Install
import { ReactComponent as H35O0H } from "../../Media/Icon/014.svg";

//Element
class Default extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    Call = () => {
        //Element
        window.location.href = "tel:+903225044455"
    }
    //Element
    Toggle = () => {
        //Element
        let Nav   = document.getElementById( "Web_QU140D" )
        //Element
        let Menu  = document.getElementsByClassName( "i64iv0" )[0]
        //Element
        let Close = document.getElementById( "Web_Z7834Y" )
        //Element
        Nav.classList.toggle( "d-none" )
        //Element
        Menu.classList.toggle( "d-none" )
        //Element
        Close.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( Container, {},
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "button", { id: "Web_QU140D", name: "Web_QU140D", className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 t4905m", type: "button", onClick: this.Toggle },
                        //Element
                        React.createElement( Y1O941, {} )
                    ),
                    //Element
                    React.createElement( "button", { id: "Web_Z7834Y", name: "Web_Z7834Y", className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 d-none x2h02i", type: "button", onClick: this.Toggle },
                        //Element
                        React.createElement( H35O0H, {} )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "button", { id: "Web_E8618W", name: "Web_E8618W", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 f387gt", type: "button", onClick: this.Home },
                        //Element
                        React.createElement( JY9F35, {} )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "button", { id: "Web_C0480X", name: "Web_C0480X", className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 bg-transparent border-0 v6i522", type: "button", onClick: this.Call },
                        //Element
                        React.createElement( FV0J33, {} )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;