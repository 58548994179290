//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Corporation from "./Component/Tab/Corporation/Default";
//Install
import Product from "./Component/Tab/Product/Default";
//Install
import Artcool from "./Component/Tab/Result/Artcool/Default";
//Install
import Sirius from "./Component/Tab/Result/Sirius/Default";
//Install
import Dualeco from "./Component/Tab/Result/Dualeco/Default";
//Install
import Dualcool from "./Component/Tab/Result/Dualcool/Default";
//Install
import Puricare from "./Component/Tab/Result/Puricare/Default";
//Install
import Minimal from "./Component/Tab/Result/Puricare/Minimal";
//Install
import Services from "./Component/Tab/Services/Default";
//Install
import Career from "./Component/Tab/Career/Default";
//Install
import Reference from "./Component/Tab/References/Default";
//Install
import Photo from "./Component/Tab/Photo/Default";
//Install
import Contact from "./Component/Tab/Contact/Default";
//Install
import Footer from "./Component/Footer";

//Element 
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/urunler" element={ <Product /> }></Route>
          <Route path="/medya/:ID" element={ <Photo /> }></Route>
          <Route path="/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" element={ <Artcool /> }></Route>
          <Route path="/urun/lg-uv-sirius-a-9000-btu-duvar-tipi-inverter-klima-dc09rth" element={ <Sirius /> }></Route>
          <Route path="/urun/lg-dual-eco-a-12000-btu-duvar-tipi-inverter-klima-s3-w12ja3aa" element={ <Dualeco /> }></Route>
          <Route path="/urun/lg-dualcool-a-18000-btu-duvar-tipi-inverter-klima-pc18sq" element={ <Dualcool /> }></Route>
          <Route path="/urun/lg-puricare-360-hava-temizleyici-oklu-filtre-sistemi-as10gdwh0" element={ <Puricare /> }></Route>
          <Route path="/urun/lg-puricare-360-hava-temizleyici-oklu-filtre-sistemi-as65gdwh0" element={ <Minimal /> }></Route>
          <Route path="/referanslar" element={ <Reference /> }></Route>
          <Route path="/servis-randevusu" element={ <Services /> }></Route>
          <Route path="/kariyer" element={ <Career /> }></Route>
          <Route path="/hemen-ulasin" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;