//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";
//Install
import Modal from "../../Shortcode/Services/Modal";
//Install
import emailjs from "@emailjs/browser";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Hemen Ulasin : Yardıma ihtiyacınız var mı? - Erkaklima.com.tr"
        //Element
        Description.content = "Bizimle iletişime geçme hakkında bilgi edinin. Kullanım kılavuzlarımız, video eğitimlerimiz, yazılım indirmelerimiz ve daha fazlası ile LG yardım ve müşteri desteği alın."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/hemen-ulasin"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Hemen Ulasin : Yardıma ihtiyacınız var mı? - Erkaklima.com.tr"
        //Element
        Description.content = "Bizimle iletişime geçme hakkında bilgi edinin. Kullanım kılavuzlarımız, video eğitimlerimiz, yazılım indirmelerimiz ve daha fazlası ile LG yardım ve müşteri desteği alın."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/hemen-ulasin"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Hemen Ulasin : Yardıma ihtiyacınız var mı? - Erkaklima.com.tr"
        //Element
        Description.content = "Bizimle iletişime geçme hakkında bilgi edinin. Kullanım kılavuzlarımız, video eğitimlerimiz, yazılım indirmelerimiz ve daha fazlası ile LG yardım ve müşteri desteği alın."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Hemen Ulasin : Yardıma ihtiyacınız var mı? - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Bizimle iletişime geçme hakkında bilgi edinin. Kullanım kılavuzlarımız, video eğitimlerimiz, yazılım indirmelerimiz ve daha fazlası ile LG yardım ve müşteri desteği alın."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal", Sub: "Hemen Ulasin", Target: "/hemen-ulasin" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-2 c558jd" },
                                    //Element
                                    React.createElement( "h4", { className: "float-start w-100 p-0 m-0 lh-1 c1349i" },
                                        //Element
                                        "Kurumsal Bilgilendirme"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 j65d9q" },
                                        //Element
                                        "Hemen bir LG Müşteri Desteği Temsilcisi ile canlı olarak sohbet etmeye başlayabilirsiniz."
                                    ),
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 q4674j", href: "tel:+903225044455" },
                                        //Element
                                        "0322 504 44 55"
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-4 c558jd" },
                                    //Element
                                    React.createElement( "h4", { className: "float-start w-100 p-0 m-0 lh-1 c1349i" },
                                        //Element
                                        "Hemen Bilgi Edinin"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 j65d9q" },
                                        //Element
                                        "Numaramızı WhatsApp'a ekleyin ve uzmanlarımız ile hemen iletişim kurun."
                                    ),
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 q4674j", href: "tel:+905321206083" },
                                        //Element
                                        "0532 120 60 83"
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-4 d-none c558jd" },
                                    //Element
                                    React.createElement( "h4", { className: "float-start w-100 p-0 m-0 lh-1 c1349i" },
                                        //Element
                                        "Hemen E-Posta Gönderin"
                                    ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 j65d9q" },
                                        //Element
                                        "Lütfen bizimle iletişime geçmek için aşağıdaki e-posta adresini kullanın."
                                    ),
                                    //Element
                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 lh-1 q4674j", href: "mailto:info@erkaklima.com.tr" },
                                        //Element
                                        "info@erkaklima.com.tr"
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "Bilgi: Lütfen talebinizi gönderirken seçtiğiniz “Konu Başlığı'nın” doğru olduğundan emin olunuz, aksi takdirde talebiniz değerlendirilmeyebilir."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 mb-4 s4096n" },
                                    //Element
                                    "Sayın Müşterimiz, talebinizi iletmek üzere olduğunuz için teşekkür ederiz. Hatırlatmak isteriz ki, talebinizi gönderirken seçeceğiniz Konu Başlığı'nın doğru olduğundan emin olmanız önemlidir. Doğru başlık seçimi hızlı işlem için önemlidir."
                                ),
                                //Element
                                React.createElement( Modal, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 g410b3" },
                                //Element
                                React.createElement( "iframe", { width: "600", height: "450", src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3184.5364277812887!2d35.27469377565701!3d37.04469807217838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1528891bc68489ad%3A0x9d4c5848bf97aaa!2sErka%20Klima!5e0!3m2!1str!2str!4v1712591145672!5m2!1str!2str", className: "float-start w-100 bg-transparent border-0" } )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;