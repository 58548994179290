//Install
import React, { useEffect } from "react";
//Install
import { Routes, Route, useParams } from "react-router-dom";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";

//Element
function Photo(){
    //Element
    useEffect( () => {
        //Element
        document.title = "Kurumsal : Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Kurumsal etik değerlere olan bağlılığı, üst düzey kalite standartlarına olan taahhüdü ve insan odaklı yaklaşımıyla, güvenilirliğin ve itibarın sembolü olmaya devam ediyor."
    })
    //Element
    let { ID } = useParams()
    //Element
    let Source = "https://cdn.erkaklima.com.tr/images/"
    //Element
    let Media  = ""
    //Element
    if( ID == "1" ){
        //Element
        Media = "001.jpg"
    //Element
    } else if( ID == "2" ){
        //Element
        Media = "002.jpg"
    //Element
    } else if( ID == "3" ){
        //Element
        Media = "003.jpg"
    //Element
    } else {
        //Element
        Media = "001.jpg"
    }
    //Element
    let Image = Source + Media
    //Element
    const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
        //Element
        React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
            //Element
            React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
        ),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal", Sub: "Foto Galeri", Target: "/kurumsal" } )
                    )
                )
            )
        ),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                            //Element
                            React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                //Element
                                "LG Electronics"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c501g2" },
                            //Element
                            React.createElement( "img", { alt: "Erka Klima Sistemleri", title: "Erka Klima Sistemleri", src: Image, width: "100", height: "100" } )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        //Element
        Content
    )
}
//Element
export default Photo;