//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Default extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Source = "https://cdn.erkaklima.com.tr/references/"
        //Element
        let Image  = Source + Photo
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-2 p0025l" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-4 m-0 v3918a" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Image, width: "89", height: "89" } )
            ),
            //Element
            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center text-truncate i01u47" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Default.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Default;