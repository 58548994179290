//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";
//Install
import Product from "../../Shortcode/Product/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Ürünler : Klima ve Hava Bakım - Erkaklima.com.tr"
        //Element
        Description.content = "Daha hızlı soğutur. Daha uzun serin kalır. Daha sessiz çalışır. Yeni nesil klima! Evinizi serin ve rahat tutun. Bizi ziyaret edin ve sizin için doğru klimayı bulun."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/urunler"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Ürünler : Klima ve Hava Bakım - Erkaklima.com.tr"
        //Element
        Description.content = "Daha hızlı soğutur. Daha uzun serin kalır. Daha sessiz çalışır. Yeni nesil klima! Evinizi serin ve rahat tutun. Bizi ziyaret edin ve sizin için doğru klimayı bulun."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/urunler"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Ürünler : Klima ve Hava Bakım - Erkaklima.com.tr"
        //Element
        Description.content = "Daha hızlı soğutur. Daha uzun serin kalır. Daha sessiz çalışır. Yeni nesil klima! Evinizi serin ve rahat tutun. Bizi ziyaret edin ve sizin için doğru klimayı bulun."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Ürünler : Klima ve Hava Bakım - Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Daha hızlı soğutur. Daha uzun serin kalır. Daha sessiz çalışır. Yeni nesil klima! Evinizi serin ve rahat tutun. Bizi ziyaret edin ve sizin için doğru klimayı bulun."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Ürünler", Sub: "Klimalar ve Hava Bakımı", Target: "/urunler" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 n3186t" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mt-4 text-center lh-1 s28e8x" },
                                    //Element
                                    "Klimalar"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b98853" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( Product, { ID: "0", Title: "LG ArtCool A++ 24000 BTU Duvar Tipi Inverter Klima AC24BH", Photo: "013.jpg", Target: "/urun/lg-artcool-a-24000-btu-duvar-tipi-inverter-klima-ac24bh" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( Product, { ID: "0", Title: "LG DualCool A++ 18000 BTU Duvar Tipi Inverter Klima PC18SQ", Photo: "003.jpg", Target: "/urun/lg-dualcool-a-18000-btu-duvar-tipi-inverter-klima-pc18sq" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( Product, { ID: "0", Title: "LG Dual Eco A++ 12000 BTU Duvar Tipi Inverter Klima S3-W12JA3AA", Photo: "005.jpg", Target: "/urun/lg-dual-eco-a-12000-btu-duvar-tipi-inverter-klima-s3-w12ja3aa" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( Product, { ID: "0", Title: "LG UV Sirius A++ 9000 BTU Duvar Tipi Inverter Klima DC09RTH", Photo: "009.jpg", Target: "/urun/lg-uv-sirius-a-9000-btu-duvar-tipi-inverter-klima-dc09rth" } )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 n3186t" },
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 mt-4 text-center lh-1 s28e8x" },
                                    //Element
                                    "Hava Bakımı"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 b98853" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( Product, { ID: "0", Title: "PuriCare 360 Hava Temizleyici Çoklu Filtre Sistemi AS10GDWH0", Photo: "017.jpg", Target: "/urun/lg-puricare-360-hava-temizleyici-oklu-filtre-sistemi-as10gdwh0" } )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( Product, { ID: "0", Title: "PuriCare 360 Hava Temizleyici Çoklu Filtre Sistemi AS65GDWH0", Photo: "021.jpg", Target: "/urun/lg-puricare-360-hava-temizleyici-oklu-filtre-sistemi-as65gdwh0" } )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;