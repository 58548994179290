//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Search } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as User } from "../../Media/Icon/002.svg";
//Install
import { ReactComponent as Cart } from "../../Media/Icon/003.svg";

//Element
class SET extends Component {
    //Element
    Prevent = (e) => {
        //Element
        e.preventDefault()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 i83a7c" },
            //Element
            React.createElement( "ul", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 w70rv2" },
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 m-0", href: "/", onClick: this.Prevent },
                        //Element
                        React.createElement( Search, {} )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-1 m-0 ms-4", href: "/", onClick: this.Prevent },
                        //Element
                        React.createElement( User, {} )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto position-relative" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 ps-1 m-0 ms-4 position-relative", href: "/", onClick: this.Prevent },
                        //Element
                        React.createElement( Cart, {} ),
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle text-center t98m9l" },
                            //Element
                            "0"
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;