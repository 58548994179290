//Install
import React, { Component } from "react";

//Element
class Figure extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "figure", { className: "float-start w-100 p-0 m-0 position-absolute start-0 top-0 bl39u4" },
            //Element
            React.createElement( "i", { className: "float-start w-auto p-0 m-0 position-absolute xt42k1" } ),
            //Element
            React.createElement( "i", { className: "float-start w-auto p-0 m-0 position-absolute b66326" } ),
            //Element
            React.createElement( "i", { className: "float-start w-auto p-0 m-0 position-absolute gu254k" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Figure;