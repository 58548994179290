//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Headline from "../../Content/Headline";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Overview";
//Install
import Photo from "../../Shortcode/Corporation/Photo";
//Install
import Share from "../../Shortcode/Share/Default";

//Element
class Default extends Component {
    //Element
    OpenGraph(){
        //Element
        let Title           = document.querySelector( "meta[property='og:title']" )
        //Element
        let Description     = document.querySelector( "meta[property='og:description']" )
        //Element
        let Image           = document.querySelector( "meta[property='og:image']" )
        //Element
        let URL             = document.querySelector( "meta[property='og:url']" )
        //Element
        Title.content       = "Kurumsal : Erkaklima.com.tr"
        //Element
        Description.content = "Kurumsal etik değerlere olan bağlılığı, üst düzey kalite standartlarına olan taahhüdü ve insan odaklı yaklaşımıyla, güvenilirliğin ve itibarın sembolü olmaya devam ediyor."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/kurumsal"
    }
    //Element
    Twitter(){
        //Element
        let Title           = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let Description     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let Image           = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let URL             = document.querySelector( "meta[name='twitter:url']" )
        //Element
        Title.content       = "Kurumsal : Erkaklima.com.tr"
        //Element
        Description.content = "Kurumsal etik değerlere olan bağlılığı, üst düzey kalite standartlarına olan taahhüdü ve insan odaklı yaklaşımıyla, güvenilirliğin ve itibarın sembolü olmaya devam ediyor."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
        //Element
        URL.content         = "https://erkaklima.com.tr/kurumsal"
    }
    //Element
    Other(){
        //Element
        let Title           = document.querySelector( "meta[itemprop='name'" )
        //Element
        let Description     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let Image           = document.querySelector( "meta[itemprop='image']" )
        //Element
        Title.content       = "Kurumsal : Erkaklima.com.tr"
        //Element
        Description.content = "Kurumsal etik değerlere olan bağlılığı, üst düzey kalite standartlarına olan taahhüdü ve insan odaklı yaklaşımıyla, güvenilirliğin ve itibarın sembolü olmaya devam ediyor."
        //Element
        Image.content       = "https://cdn.erkaklima.com.tr/brand/000.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title = "Kurumsal : Erkaklima.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content = "Kurumsal etik değerlere olan bağlılığı, üst düzey kalite standartlarına olan taahhüdü ve insan odaklı yaklaşımıyla, güvenilirliğin ve itibarın sembolü olmaya devam ediyor."
        //Element
        this.OpenGraph()
        //Element
        this.Twitter()
        //Element
        this.Other()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 j64fh9" },
            //Element
            React.createElement( "section", { className: "float-start w-100 position-relative r383mb" },
                //Element
                React.createElement( Headline, { ID: "0", Title: "", Summary: "", Video: "001.mp4", Target: "/" } )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 e66x2v" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Breadcrumb, { ID: "0", Title: "Kurumsal", Sub: "Hakkımızda", Target: "/kurumsal" } )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 wo9836" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "LG Electronics"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 n3186t" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c2l68g" } ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-100 p-0 m-0 o625uj" },
                                    //Element
                                    "Kurumsal etik değerlere olan bağlılığı, üst düzey kalite standartlarına olan taahhüdü ve insan odaklı yaklaşımıyla, güvenilirliğin ve itibarın sembolü olmaya devam ediyor."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "LG Klima olarak, klima sektöründe uzun yıllara dayanan deneyimimizle hizmet vermekte ve müşterilerimize kaliteli ürünler ve olağanüstü müşteri memnuniyeti sunmayı amaçlamaktayız."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Kuruluşumuzdan bu yana şirketimizin temel değerleri olan dürüstlük, şeffaflık ve müşteri odaklılık ilkesi doğrultusunda hareket etmekteyiz. Her bir müşterimizin ihtiyaçlarını anlamak ve en uygun çözümleri sunmak için çaba sarf ediyoruz. Bu çerçevede, sadece ürünlerimizin kalitesine değil, aynı zamanda sunduğumuz hizmetin kalitesine de büyük önem veriyoruz."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Ürün portföyümüzde yer alan klima çeşitleri, yenilikçi tasarımları ve enerji verimliliği ile dikkat çekiyor. LG'nin klima ürünleri, en son teknolojileri ve yenilikleri birleştirerek, müşterilerimize olağanüstü konfor ve performans sunmayı amaçlamaktadır."
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 n3186t" },
                                //Element
                                React.createElement( "video", { className: "float-start w-100 h-auto", playsInline: true, src: "https://erka.s3.eu-north-1.amazonaws.com/video/002.mp4", controls: true, loop: true, autoPlay: true, muted: "muted" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:"8 offset-md-4" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 n3186t" },
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 s4096n" },
                                    //Element
                                    "LG Klima Bayi olarak, sadece işimizi yapmakla kalmayıp aynı zamanda çevresel ve toplumsal sorumluluklarımızın bilincindeyiz. Sürdürülebilirlik ilkelerine bağlı kalarak, çevresel etkileri en aza indirmek için çaba gösteriyor ve toplumsal sorumluluk projelerine aktif olarak katılıyoruz."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 s4096n" },
                                    //Element
                                    "Bizleri sektörde farklı kılan en önemli unsurlardan biri insan kaynağımızdaki önemli yeteneklerdir. Ekip arkadaşlarımız, alanında uzman, deneyimli ve motivasyonu yüksek profesyonellerden oluşmaktadır. Birlikte çalışma kültürümüzde açık iletişim, işbirliği ve sürekli öğrenme ve gelişme ön plandadır."
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-4 mb-2 s4096n" },
                                    //Element
                                    "Müşteri memnuniyetini her zaman önceliğimiz olarak gören LG Electronics, siz değerli müşterilerimizle uzun vadeli bir ilişki kurmayı amaçlıyoruz. Sektördeki lider konumumuzu sürdürmek ve daha da ileriye taşımak için çalışmaya devam edeceğiz."
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 q072j5" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c4eh00" },
                                            //Element
                                            React.createElement( Photo, { ID: "0", Title: "Erka Klima Sistemleri", Image: "001.jpg", Target: "/medya/1" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c4eh00" },
                                            //Element
                                            React.createElement( Photo, { ID: "0", Title: "Erka Klima Sistemleri", Image: "002.jpg", Target: "/medya/2" } )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:4 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c4eh00" },
                                            //Element
                                            React.createElement( Photo, { ID: "0", Title: "Erka Klima Sistemleri", Image: "003.jpg", Target: "/medya/3" } )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:"8 offset-md-4" },
                            //Element
                            React.createElement( Share, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;